import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';





@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  token: string;
  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }
  constructor(private authService: AuthService, private toastr: ToastrService, private router: Router){
    
  }

  /*canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn.pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.router.navigate(['/login']);
          return false;
        }else{
          var element = document.getElementById("mainLoginPanel");
          if (element.classList.contains('main-panel-login')) {
            // do some stuff
            element.classList.remove("main-panel-login");
        }
       
        }
        return true;
      })
    );
  }*/

  canActivate(next :ActivatedRouteSnapshot, state:RouterStateSnapshot):boolean{
    let tokenExpire = false;
    this.token = localStorage.getItem('tokenId');
    if (this.token == '' || this.token == null) {
          this.toastr.error("You must login to continue.");

      // this.popToast('warning', 'You must login to continue.');
      this.router.navigate(['/login']);
      return false;
    } 
      else {
        // this.authService.login;
        this.authService.login();
        var element = document.getElementById("mainLoginPanel");
         if(element!=null || element!=undefined){
          if (element.classList.contains('main-panel-login')) {
            // do some stuff
            element.classList.remove("main-panel-login");
        }
         }
         
        return true;
      }
    }
  
}
