import { Injectable } from '@angular/core';

import { HttpHeaders, HttpClient, HttpParams,HttpRequest,HttpEvent, HttpErrorResponse} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

   token: any;
   //  apiBaseUrl = 'http://localhost:3000/';
  //apiBaseUrl = 'https://api.insidecricket.co.in:445/';
  apiBaseUrl = 'https://api.rollball.org:444/';
  

  constructor(private  http:  HttpClient, public router: Router) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'x-access-token': ''
    })
  };

  httpOptionsput = {
    headers: new HttpHeaders({ 
      'x-access-token': 'token'
    })
  };
  public headers = new HttpHeaders().set('x-access-token', 'Bearer ');

  setToken(tokenString) {
    this.httpOptions.headers = this.httpOptions.headers.set('x-access-token', tokenString);
   // this.httpOptionsadd.headers = this.httpOptionsadd.headers.set('x-access-token', tokenString);
    this.httpOptionsput.headers = this.httpOptionsput.headers.set('x-access-token', tokenString);
    this.headers = new HttpHeaders().set('x-access-token', tokenString);
    //console.log(this.httpOptions.headers.get('x-access-token'));
   this.token = tokenString;
 }

 getData(url): Observable<any> {
  return this.http.get( this.apiBaseUrl+url, this.httpOptions)
  .pipe(
        data =>  data,
       catchError(this.handleError)
        //  error => error
      );
}
  putData(url, data): Observable<any> {
    return this.http.put(this.apiBaseUrl+url, data, this.httpOptions).pipe(
      respdata => respdata,
      catchError(this.handleError)
       //error => error
    );
  }

  postData(url, data): Observable<any> {
    /*const httpOptionsPostData = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'x-access-token': token
      })
    };*/
    return this.http.post(this.apiBaseUrl+url, data, this.httpOptions).pipe(
      respdata => respdata,
      catchError(this.handleError)
       //error => error
    );
  }

  

  post(url, data): Observable<any> {
    return this.http.post(this.apiBaseUrl+url, data, this.httpOptionsput).pipe(
      respdata => respdata,
      catchError(this.handleError)
     //  error => error
    );
  }

 deleteData(url): Observable<any> {
    return this.http.delete(this.apiBaseUrl+url, this.httpOptions).pipe(
      respdata => respdata,
      catchError(this.handleError)
     //  error => error
    );
  }

  putDataFIle(url, data): Observable<any> {
    return this.http.put(this.apiBaseUrl + url, data, this.httpOptionsput).pipe(
      respdata => respdata,
      catchError(this.handleError)
     //  error => error
    );
  }



  
  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      if (error.status === 401) {
        window.sessionStorage.clear();
        window.localStorage.clear();
        localStorage.clear();
        // window.location.hash = "login";
        // this.nav.sessionExpired();
        
        // window.open('/#/login', '_self');
        // if(!window.location.href.includes('login') && window.location.href.split('#').length>1)
         window.location.reload();
      }
    }
    // return an observable with a user-facing error message
    return throwError(error);//throwError('Something bad happened; please try again later.');
  }
  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) { 
        currentPage = 1; 
    } else if (currentPage > totalPages) { 
        currentPage = totalPages; 
    }
    
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
        // less than 10 total pages so show all
        startPage = 1;
        endPage = totalPages;
    } else {
        // more than 10 total pages so calculate start and end pages
        if (currentPage <= 6) {
            startPage = 1;
            endPage = 10;
        } else if (currentPage + 4 >= totalPages) {
            startPage = totalPages - 9;
            endPage = totalPages;
        } else {
            startPage = currentPage - 5;
            endPage = currentPage + 4;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
}
}

