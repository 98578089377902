import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  isLoggedIn$: Observable<boolean>;
      // isLoggedIn$ : any;

  constructor(private authService: AuthService, public router: Router){

  }

  ngOnInit() { 
   
    this.isLoggedIn$ = this.authService.isLoggedIn;
    if(this.isLoggedIn$){
      var element = document.getElementById("mainLoginPanel");
      if(element != null ||  element != undefined ){
        if (element.classList.contains('main-panel-login')) {
          // do some stuff
          element.classList.remove("main-panel-login");
      }
      
      }
    }
 
  }


}
