import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth.service';
import { Observable } from 'rxjs';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    // { path: '/dashboard',     title: 'Dashboard',         icon:'nc-bank',       class: '' },
     { path: '/player',       title: 'Players',    icon:'nc-circle-10',  class: '' },
    { path: '/associations',       title: 'Associations',    icon:'nc-globe',  class: '' },
    { path: '/feestructure',       title: 'Fee Structure',    icon:'nc-money-coins',  class: '' },
    { path: '/tournament',       title: 'Tournament',    icon:'nc-chart-bar-32',  class: '' },
    { path: '/courses',       title: 'Registered Coueses',    icon:'nc-icon nc-badge',  class: '' },
   


];



@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {

     public menuItems: any[];
    public menuItems1: any[];
    member: boolean;
    scholorship: boolean;
    menuItems2: RouteInfo[];
    vadhuvar: boolean;
    ngOnInit() {
       
        this.member=true;
      
        this.menuItems = ROUTES.filter(menuItem => menuItem);
      
    }

    
}
