import { Component, OnInit } from '@angular/core';
import { ApiService } from './pages/service/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  constructor(private serviceApi: ApiService){
    
  }
    ngOnInit() {
      var localtoken = localStorage.getItem('tokenId');
      var localUserLoginId = localStorage.getItem('userId');

      if(localtoken == "" || localtoken == null || localtoken=="null" || localUserLoginId == null){
        console.log("localtoken :", localtoken);
        console.log("localUserLoginId :", localUserLoginId);
      }
      else{
        window.sessionStorage.setItem('tokenId', localStorage.getItem('tokenId'));
        console.log("Call SetToken from app component");
        this.serviceApi.setToken(localStorage.getItem('tokenId'));

      }
    }
}
