import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
// import { User } from './user';

@Injectable()
export class AuthService {
  // var token=  localStorage.getItem("tokenId");
  // private loggedIn;

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isLoggedIn() {
    return this.loggedIn.asObservable();

  /*  var token=  localStorage.getItem("tokenId");
    if (token == '' || token == null || token==undefined) {
      // this.router.navigate(['/dashboard']);
      return false;
    }else{
      return true;
    }*/
    // return this.loggedIn.asObservable();
  }

  constructor(
    private router: Router
  ) {}

  /*isLoggedIn() {
    var token=  localStorage.getItem("tokenId");
    if (token == '' || token == null || token==undefined) {
      // this.router.navigate(['/dashboard']);
      return false;
    }else{
      return true;
    }
    // return this.loggedIn.asObservable();
  }*/

  login() {
  //  var token=  localStorage.getItem("tokenId");
    // if (token !== '') {
      this.loggedIn.next(true);

      // this.router.navigate(['/dashboard']);
    // }
  }

  logout() {
    this.loggedIn.next(false);
    localStorage.clear();
    sessionStorage.clear();
    var element = document.getElementById("mainLoginPanel");
    if((element != null ||  element != undefined)  ){
      if (element.classList.contains('main-panel-login') && localStorage.getItem('tokenId') != null) {
        // do some stuff
        element.classList.remove("main-panel-login");
    }
    else{
      element.classList.add("main-panel-login");
  
    }
    }    
    this.router.navigate(['/login']);
  }
}

export interface User {
  userName: string;
  password: string;
}